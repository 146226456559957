
import VueRecaptcha from "vue-recaptcha";
import { Component, Mixins } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";
import vueFilePond, { setOptions } from "vue-filepond";
import comunas from "@/helpers/comunas.json";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize
);

interface ticketData {
  rut: string;
  name: string;
  father_last_name: string;
  mother_last_name: string;
  document_type: string;
  document_id: string;
  street: string;
  street_number: string;
  district: {
    value: number;
    label: string;
  };
  phone: number | null;
  email: string;
  message: string;
  numero_cliente: string;
  numero_reclamo: string;
  reclamoDirecto: string;
  energia: string;
  tipoReclamo: string;
}

@Component({
  components: {
    FilePond,
    VueRecaptcha
  }
})
export default class SendStreetLightTicketForm extends Mixins(FormValidator) {
  protected formData: ticketData = {
    rut: "",
    name: "",
    father_last_name: "",
    mother_last_name: "",
    document_type: "1",
    document_id: "",
    street: "",
    street_number: "",
    district: {
      value: 108,
      label: "La Reina"
    },
    phone: null,
    email: "",
    message: "",
    numero_cliente: "",
    numero_reclamo: "",
    reclamoDirecto: "",
    energia: "",
    tipoReclamo: ""
  };
  options_street: any = [];
  options_district: any = comunas;
  street_loading = false;
  street_search_empty = false;
  disable_button = false;
  options_reclamo: any = [
    "Interrupción no programada de servicio",
    "Daños de artefactos"
  ];
  options_energy_status = ["Sí", "No"];

  options_reclamo_directo = ["Sí", "No"];

  protected captcha_key = "";
  private beforeMount() {
    this.captcha_key = process.env.VUE_APP_KEY_CAPTCHA as string;
  }
  private onVerify(response: any) {
    this.validateForm();
  }

  private validateForm() {
    (this.$refs.registerForm as any).validate().then((response: Boolean) => {
      if (response) this.sendTicketAttemp();
      else this.notifyError("Debe completar todos los campos requeridos");
    });
  }

  private onExpired() {
    this.$q.notify({
      color: "negative",
      message: "Por favor, recargue la página.",
      icon: "report_problem",
      position: "top",
      timeout: 2500
    });
  }

  private onError() {
    this.$q.notify({
      color: "negative",
      message: "Ha ocurrido un error, intente más tarde.",
      icon: "report_problem",
      position: "top",
      timeout: 2500
    });
  }
  private filterDistrict(val: string, update: any) {
    if (val === "") {
      update(() => {
        this.options_district = comunas;
      });
      return;
    }

    update(() => {
      const value = val.toLowerCase();
      this.options_district = (comunas as any).filter((comuna: any) => {
        return (
          comuna.label.toLowerCase().indexOf(value) > -1 ||
          comuna.label === this.formData.district.label
        );
      });
      if (this.formData.district) {
        this.options_district.unshift(this.formData.district);
      }
    });
  }

  private async filterStreet(val: string, update: any, abort: any) {
    this.street_loading = true;
    if (val === "") {
      update(() => {
        this.options_street = [];
      });
      this.street_loading = false;
      this.street_search_empty = true;
      return;
    }
    await this.loadStreets(val);

    setTimeout(() => {
      update(() => {});
    }, 200);

    return;
  }

  private async loadStreets(val: string) {
    const value = val.toLowerCase();
    let streets = await this.apiStreets(val);
    if (streets.err) {
      this.options_street = [];
      return;
    }
    this.street_loading = false;
    this.street_search_empty = false;
    this.options_street = streets;
  }

  private areAllFilesOK(): boolean {
    let files = (this.$refs.pond as any).getFiles();
    for (let i = 0; i < files.length; i++) {
      if (files[i].status != 5) {
        return false;
      }
    }
    return true;
  }

  private triggerFilepond() {
    (document.getElementsByClassName(
      "filepond--browser"
    )[0] as HTMLInputElement).click();
  }

  private typeDetection(file: any, type: any) {
    let checkExtensions = ["doc", "docx", "xls", "xlsx", "ppt", "pptx"];
    let isAllowed = new Promise((resolve, reject) => {
      let extension = file.name
        .split(".")
        .pop()
        .toLowerCase();
      if (checkExtensions.indexOf(extension) > -1) {
        let officeType = "";
        switch (extension) {
          case "doc":
          case "docx":
            officeType = "application/doc";
            break;
          case "xls":
          case "xlsx":
            officeType = "application/xls";
            break;
          case "ppt":
          case "pptx":
            officeType = "application/ppt";
            break;
          default:
            officeType = "";
            break;
        }
        if (officeType === "") {
          reject();
        } else {
          resolve(officeType);
        }
      } else {
        resolve(type);
      }
    });
    return isAllowed;
  }

  private mounted() {}

  private options_document: object[] = [
    { label: "DNI", value: "0" },
    { label: "RUT", value: "1" },
    { label: "Pasaporte", value: "2" }
  ];

  private async getServiceNumber(): Promise<number> {
    // @todo - obtener parámetros desde la api
    const response = await this.$axios
    .post(
      "servicio_metas/formulario",
      {
        meta_key: "formulario",
        meta_value: "reclamo_electricidad"
      }
    )
    return Number(response.data)
  }
  private clearStreet(): void {
    if (this.formData.district.label == this.comuna_cliente) {
      this.formData.street = "";
    }
  }

  private getTicketMessage() {
    let ticketMessage = "Datos de la solicitud : \n";
    ticketMessage = ticketMessage.concat("Número de cliente: ".concat(this.formData.numero_cliente));
    ticketMessage = ticketMessage.concat("\nTipo de reclamo: ".concat(this.formData.tipoReclamo));
    ticketMessage = ticketMessage.concat("\n¿Tiene energía?: ".concat(this.formData.energia));
    ticketMessage = ticketMessage.concat("\n¿Hizo reclamo directo?: ".concat(this.formData.reclamoDirecto));
    if (this.formData.numero_reclamo)
      ticketMessage = ticketMessage.concat("\nNúmero de reclamo:".concat(this.formData.numero_reclamo));

    ticketMessage = ticketMessage.concat("\n".concat(this.formData.message));
    return ticketMessage;
  }

  private notifyError(message: string) {
    this.$q.notify({
      message: message,
      color: "accent",
      position: "top",
      timeout: 7500
    });
    this.disable_button = false;
  }

  private async sendTicketAttemp() {
    this.disable_button = true;
    if (!this.areAllFilesOK()) {
      this.notifyError(this.$t("notification.problem_with_files") as string);
      return;
    }
    let captcha = (window as any).grecaptcha.getResponse();
    if (captcha == "") {
      this.$q.notify({
        message: this.$t("notification.check_captcha") as string,
        color: "accent",
        position: "top",
        timeout: 7500
      });
      return (this.disable_button = false);
    }

    //ordenar input de archivos filepond)
    let adjuntos_input = document.getElementsByName("Files");
    //backend no soporta vacio, debe ser null
    let adjuntos_empty = true;
    let adjuntos: any = [];
    for (let i = 0; i < adjuntos_input.length; i++) {
      adjuntos[i] = (adjuntos_input[i] as HTMLInputElement).value;
    }
    for (let j = 0; j < adjuntos.length; j++) {
      if (adjuntos[j] !== "") {
        adjuntos_empty = false;
      }
    }

    if (adjuntos_empty)
      return this.notifyError('Debe adjuntar al menos uno de los documentos solicitados.')

    let serviceNumber = 0
    try {
      serviceNumber = await this.getServiceNumber()
    } catch (error) {
      return this.notifyError('Ha ocurrido un error al generar la solicitud. Por favor inténtelo más tarde.')
    }
    // enviar post al backend.
    this.$axios
      .post(
        "tickets/solicitud/feriaEmprendimiento",
        {
          ticket: {
            id_servicio: serviceNumber,
            mensaje: this.getTicketMessage(),
            origen: 4,
            tipo: 0,
            direccion: {
              id_comuna: 108,
              calle: "Alcalde Fernando Castillo Velasco",
              numero: 9954,
              aclaratoria: ""
            }
          },
          direccion: {
            id_comuna: this.formData.district.value,
            calle: this.formData.street,
            numero: this.formData.street_number,
            aclaratoria: ""
          },
          dispositivo: navigator.userAgent,
          captcha: captcha,
          persona: {
            rut: this.formData.document_id,
            nombre: this.formData.name,
            apellido_paterno: this.formData.father_last_name,
            apellido_materno: this.formData.mother_last_name,
            email: this.formData.email,
            telefono: this.formData.phone,
            tipo_documento: 1,
            id_pais: 47
          },
          adjunto: adjuntos
        },
        {
          headers: {
            Authorization: this.$store.state.user.auth_token
              ? this.$store.state.user.auth_token
              : null,
            Captcha: captcha
          }
        }
      )
      .then(response => {
        let ticket = {
          id: response.data.id_ticket,
          guest_id: this.formData.document_id,
          guest_name: this.formData.name,
          guest_lastname: this.formData.father_last_name,
          guest_mother_lastname: this.formData.mother_last_name,
          message: response.data.mensaje,
          date: response.data.fecha,
          service_name: response.data.service_name,
          step: 4,
          adjuntos: response.data.adjunto ? response.data.adjunto : null,
          tipo: 0
        };
        this.$store.dispatch("submitFinishedGuestRequest", ticket);
      })
      .catch(error => {
        return error;
      });
  }

  private filepondServer = {
    url: process.env.VUE_APP_API_PATH + "/temporal_archivos/",
    process: {
      url: "upload",
      method: "PUT",
      withCredentials: false,
      onload: (response: string) => {
        let json_response = JSON.parse(response);
        return json_response.response.nombre;
      },
      onerror: (response: any) => response.data,
      ondata: (formData: any) => {
        return formData;
      }
    },
    revert: "delete",
    restore: null,
    load: null,
    fetch: null
  };
}
