
import { Component, Vue } from "vue-property-decorator";
import SendStreetLightTicketForm from "@/components/forms/SendStreetLightTicketForm.vue";
import FormCard from "@/components/FormCard.vue";

@Component({
  components: {
    SendStreetLightTicketForm,
    FormCard
  }
})
export default class SendStreetLightRequest extends Vue {
  private mounted() {
    window.scrollTo(0, 0);
  }
}
